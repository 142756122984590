<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="证书名称">
              <a-input placeholder="请输入" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="证书类型">
              <a-select v-model="search.type" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="item in certTypeList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="3" :xl="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" placeholder="请选择">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
                <a-select-option value="1">启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="创建证书"
          class="pxkeji_basic_btn"
          @click="createCertificate('0')"
          type="primary"
          :roles="['证书-证书添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(1)"
          :roles="['证书-修改状态']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(0)"
          type="danger"
          :roles="['证书-修改状态']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="validity" slot-scope="validity">
          <span v-if="validity == 0">永久</span>
          <span v-else>{{ validity }}</span>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="trigger" slot-scope="trigger">
          <template v-if="trigger">
            <template v-if="trigger.triggerType == 0">
              学分({{ trigger.triggerSetting }})
            </template>
            <template v-if="trigger.triggerType == 1">
              学时({{ trigger.triggerSetting }})
            </template>
            <template v-if="trigger.triggerType == 2">关联课程</template>
            <template v-if="trigger.triggerType == 3">关联考试</template>
          </template>
          <template v-else></template>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            :roles="['证书-证书修改']"
            @click="createCertificate(action.id)"
            size="small"
            type="primary"
          />
          <pxkeji-btn
            v-if="action.previewUrl"
            name="预览"
            @click="showPreview(action.previewUrl)"
            size="small"
          />
          <pxkeji-btn
            name="条件设置"
            size="small"
            @click="setTrigger(action.id)"
            type="primary"
            :roles="['证书-证书条件设置']"
          />
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <pxkeji-btn
                  name="禁用"
                  :roles="['证书-修改状态']"
                  @click="handleProhibit(action.id, 0)"
                  v-if="action.status == 1"
                  size="small"
                  type="danger"
                />
                <pxkeji-btn
                  name="启用"
                  :roles="['证书-修改状态']"
                  @click="handleProhibit(action.id, 1)"
                  v-else
                  size="small"
                />
              </a-menu-item>
              <a-menu-item key="2">
                <a-popconfirm
                  title="是否确认删除此证书"
                  @confirm="delInfo(action.id)"
                >
                  <a-icon slot="icon" type="question-circle-o" />
                  <pxkeji-btn
                    name="删除"
                    :roles="['证书-证书删除']"
                    size="small"
                    type="danger"
                  />
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
            <a-button size="small" type="link"> ... </a-button>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <CertificateInfo @callbackMethod="save" :id="addedit.info.id" />
    </a-modal>

    <a-modal
      title="发证条件设置"
      v-model="triggerModal.triggerShow"
      :footer="false"
      destroyOnClose
      width="800px"
      :maskClosable="false"
      class="ui-modal"
    >
      <Trigger
        @callbackMethod="closeTrigger"
        :id="triggerModal.id"
        :dataType="2"
      />
    </a-modal>

    <a-modal
      v-model="previewModal.show"
      :footer="false"
      width="60%"
      destroyOnClose
      forceRender
    >
      <img style="width: 100%" :src="previewModal.url" />
    </a-modal>
  </div>
</template>
<script>
import CertificateInfo from "./CertificateInfo.vue"; //新增编辑
import Trigger from "../element/trigger.vue"; //发证条件设置

const columns = [
  {
    dataIndex: "name",
    align: "center",
    title: "证书名称",
    ellipsis: true,
  },
  {
    dataIndex: "typeName",
    align: "center",
    title: "证书类型",
    ellipsis: true,
  },
  {
    dataIndex: "validity",
    align: "center",
    title: "有效期(年)",
    width: 120,
    scopedSlots: { customRender: "validity" },
  },
  {
    dataIndex: "issueAgency",
    align: "center",
    title: "发放机构",
    ellipsis: true,
  },
  {
    dataIndex: "trigger",
    align: "center",
    title: "证书条件",
    width: 100,
    scopedSlots: { customRender: "trigger" },
  },
  {
    dataIndex: "updateTime",
    align: "center",
    title: "更新时间",
    width: 180,
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    key: "action",
    align: "center",
    title: "操作",
    width: 240,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "CertificateList",
  data() {
    return {
      certTypeList: [],
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 292,
      },
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      search: {
        name: "",
        status: "",
      },
      selectRows: [], //选中项编号
      selectRowId: "",
      isloading: false,
      triggerModal: {
        triggerShow: false,
        id: 0,
      },
      previewModal: {
        show: false,
        url: "",
      },
    };
  },
  components: {
    CertificateInfo,
    Trigger,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getCertTypeList();
    _this.GetList();
  },
  methods: {
    closePreview() {
      _this.previewModal.show = false;
    },
    showPreview(url) {
      _this.previewModal.show = true;
      _this.previewModal.url = url;
    },
    setTrigger(id) {
      _this.triggerModal.triggerShow = true;
      _this.triggerModal.id = id;
    },
    closeTrigger(flag) {
      _this.triggerModal.triggerShow = false;
      if (flag) {
        _this.GetList();
      }
    },
    async getCertTypeList() {
      let result = await _this.$api.dictionaryItem.getDictionaryItem(33);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.certTypeList = result.data;
      }
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.cancelSelect();
      _this.$api.certificate
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.type,
          _this.search.name,
          _this.search.status
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    //打开详情
    createCertificate(id) {
      _this.addedit.info = { id };
      _this.addedit.title = id > 0 ? "编辑" : "添加";
      _this.addedit.visible = true;
    },
    changeStatusBatch(status) {
      let msg = status == 1 ? "启用" : "禁用";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的证书吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.handleProhibit(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    /*禁用启用 */
    handleProhibit(ids, status) {
      _this.isloading = true;
      _this.$api.certificate
        .updateStatus(ids, status)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.certificate
        .deleteById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

