<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <a-form-model
      ref="contentForm"
      :rules="rules"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="内容类型" prop="type">
            <a-select
              v-model="form.type"
              placeholder="请选择"
              @change="onTypeChange"
            >
              <a-select-option
                v-for="item in contentTypeList"
                :key="item.type"
                :value="item.type"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="别名" prop="alias">
            <a-input v-model.number="form.alias" placeholder="请输入"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item
            label="横坐标"
            prop="offsetX"
            :label-col="{ span: 12 }"
            :wrapper-col="{ span: 12 }"
          >
            <a-input
              v-model.number="form.offsetX"
              placeholder="请输入"
              :maxLength="4"
              suffix="像素"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
            label="纵坐标"
            prop="offsetY"
            :label-col="{ span: 12 }"
            :wrapper-col="{ span: 12 }"
          >
            <a-input
              v-model.number="form.offsetY"
              placeholder="请输入"
              :maxLength="4"
              suffix="像素"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
            label="重置横坐标"
            prop="resetOffsetX"
            :label-col="{ span: 12 }"
            :wrapper-col="{ span: 12 }"
          >
            <a-input
              v-model.number="form.resetOffsetX"
              placeholder="请输入"
              :maxLength="4"
              suffix="像素"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="form.type && form.type != 0">
        <a-col :span="12">
          <a-form-model-item label="图片宽度" prop="width">
            <a-input
              v-model.number="form.width"
              placeholder="请输入"
              :maxLength="4"
              suffix="像素"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="图片高度" prop="height">
            <a-input
              v-model.number="form.height"
              placeholder="请输入"
              :maxLength="4"
              suffix="像素"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="form.type == 1 || form.type == 3">
        <a-col :span="12" v-if="form.type == 1">
          <a-form-model-item label="二维码内容" prop="content">
            <a-input
              v-model="form.content"
              placeholder="请输入"
              :maxLength="250"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="form.type == 1 ? '二维码logo' : '自定义图片'"
          >
            <a-upload
              accept="image/png,image/jpeg"
              :action="$api.file.uploadFile"
              listType="picture-card"
              :beforeUpload="beforeUpload"
              :fileList="fileList"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="form.logo" />
            </a-modal>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" v-if="form.type == 3">
          <a-form-model-item label="图片地址">
            <a-input
              v-model="form.content"
              placeholder="请输入"
              :maxLength="250"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div v-if="form.type == 0">
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="证书内容"
              prop="content"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input
                type="textarea"
                v-model="form.content"
                placeholder="请输入"
                :maxLength="250"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="文字区域有效长度" prop="validLength">
              <a-input
                v-model.number="form.validLength"
                placeholder="请输入"
                :maxLength="4"
                suffix="像素"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="字体" prop="typeface">
              <a-select v-model="form.typeface" placeholder="请选择">
                <a-select-option value="宋体">宋体</a-select-option>
                <a-select-option value="微软雅黑">微软雅黑</a-select-option>
                <a-select-option value="楷体">楷体</a-select-option>
                <a-select-option value="黑体">黑体</a-select-option>
                <a-select-option value="隶书">隶书</a-select-option>
                <a-select-option value="Calibri">Calibri</a-select-option>
                <a-select-option value="Arial">Arial</a-select-option>
                <a-select-option value="Times New Roman">
                  Times New Roman
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="字体颜色">
              <a-input
                v-model="form.color"
                placeholder="字体颜色"
                type="color"
                @change="colorChange"
                style="width: 80px"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="字号" prop="fontSize">
              <a-input
                v-model.number="form.fontSize"
                placeholder="字号"
                :maxLength="3"
                suffix="像素"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="对齐方式" prop="align">
              <a-radio-group v-model="form.align">
                <a-radio :value="0">左对齐</a-radio>
                <a-radio :value="1">居中</a-radio>
                <a-radio :value="2">右对齐</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="首行缩进">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="form.firstIndent"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="是否加粗">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="form.strong"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="行间距" prop="lineHeight">
              <a-select v-model="form.lineHeight" placeholder="请选择">
                <a-select-option :value="1.0">1倍</a-select-option>
                <a-select-option :value="1.3">1.3倍</a-select-option>
                <a-select-option :value="1.5">1.5倍</a-select-option>
                <a-select-option :value="1.7">1.7倍</a-select-option>
                <a-select-option :value="2.0">2倍</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 4 }">
        <pxkeji-btn name="确定" type="primary" @click="confirmToSave" />
        <pxkeji-btn
          name="取消"
          type="default"
          @click="cancelClick"
          class="cancelBtn"
        />
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>
<script>
let _this;
export default {
  props: {
    contentObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 8 },
      wrapperCol: { span: 15 },
      contentTypeList: [],
      form: {
        type: 0,
        color: "#000000",
        align: 0,
        resetOffsetX: 0,
        lineHeight: 1.5,
      },
      rules: {
        type: [
          { required: true, message: "请选择配置内容类型", trigger: "change" },
        ],
        alias: [{ required: true, message: "请输入别名", trigger: "blur" }],
        offsetX: [
          { required: true, message: "请输入横坐标", trigger: "blur" },
          { validator: checkNum, trigger: "change" },
        ],
        offsetY: [
          { required: true, message: "请输入纵坐标", trigger: "blur" },
          { validator: checkNum, trigger: "change" },
        ],
        resetOffsetX: [{ validator: checkNum, trigger: "change" }],
        width: [
          { required: true, message: "请输入图片宽", trigger: "blur" },
          { validator: checkNum, trigger: "change" },
        ],
        height: [
          { required: true, message: "请输入图片高", trigger: "blur" },
          { validator: checkNum, trigger: "change" },
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        typeface: [
          { required: true, message: "请选择字体", trigger: "change" },
        ],
        validLength: [
          {
            required: true,
            message: "请输入文字区域有效长度",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "change" },
        ],
        fontSize: [
          {
            required: true,
            message: "请输入字号",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "change" },
        ],
        align: [
          { required: true, message: "请选择对齐方式", trigger: "change" },
        ],
        lineHeight: [
          { required: true, message: "请选择行间距", trigger: "change" },
        ],
      },
      fileList: [],
      previewVisible: false,
    };
  },
  mounted() {
    _this = this;
    _this.getContentTypeList();
    if (_this.contentObj) {
      _this.isloading = true;
      _this.form = { ..._this.contentObj };
      if (_this.contentObj.colorRGB) {
        _this.form.color = _this.$commen.RGB2Hex(_this.contentObj.colorRGB);
      }
      if (_this.contentObj.logo) {
        _this.fileList.push({
          uid: 1,
          name: "图片1.png",
          status: "done",
          url: _this.contentObj.logo,
        });
      }
      _this.$nextTick(() => {
        _this.isloading = false;
      });
    }
  },
  methods: {
    getContentTypeList() {
      _this.$api.certificate.getContentTypeList().then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.contentTypeList = res.data;
        }
      });
    },
    colorChange() {
      _this.form.colorRGB = _this.$commen.toColorRGB(_this.form.color);
    },
    onTypeChange() {
      _this.$refs.contentForm.clearValidate();
    },
    confirmToSave() {
      _this.$refs.contentForm.validate((valid) => {
        if (valid) {
          if (
            _this.form.validLength +
              _this.form.offsetX -
              _this.form.resetOffsetX <
            0
          ) {
            _this.$message.error(
              "文字区域有效长度不能小于重置横坐标和横坐标之差"
            );
            return false;
          }
          let formData = { ..._this.form };
          if (formData.color) {
            delete formData.color;
          }
          _this.$emit("callback", JSON.stringify(formData));
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    beforeUpload(file) {
      var isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        _this.$message.error("文件不能超过5MB!");
      }
      return isJPGPNG && isLt5M;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.form.logo = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.form.logo = "";
      }
    },
    cancelClick() {
      _this.$emit("callback");
    },
  },
};
</script>
<style>
</style>