<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="certificateForm"
        :rules="rules"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="证书类型" prop="type">
              <a-select v-model="form.type" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="item in certTypeList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="证书名称" prop="name">
              <a-input
                placeholder="请输入"
                v-model="form.name"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="证书有效期"
              prop="validity"
              help="0代表永久有效"
            >
              <a-input
                placeholder="请输入"
                v-model.number="form.validity"
                :maxLength="3"
                suffix="年"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="证书背景图" required>
              <a-upload
                accept="image/png,image/jpeg"
                :action="$api.file.uploadFile"
                listType="picture-card"
                :beforeUpload="beforeUpload"
                :fileList="fileList"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="form.imageUrl" />
              </a-modal>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="证书编号前缀" prop="numPrefix">
              <a-input
                placeholder="请输入"
                v-model="form.numPrefix"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="编号顺序号位数" prop="sequenceLength">
              <a-input
                placeholder="请输入"
                v-model.number="form.sequenceLength"
                :maxLength="3"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="发证机构">
              <a-input
                placeholder="请输入"
                v-model="form.issueAgency"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item label="培训班统一证书">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="form.trainGeneral"
              />
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="状态">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="form.status"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-button
              type="primary"
              @click="showModal(null)"
              style="position: absolute; right: 35px; top: -42px"
            >
              设置内容
            </a-button>
            <a-table
              rowKey="id"
              size="small"
              bordered
              :columns="contentList.columns"
              :pagination="false"
              :dataSource="contentList.datas"
              :locale="{ emptyText: '暂无数据' }"
            >
              <span slot="type" slot-scope="type">
                <template v-if="type == 0">文本</template>
                <template v-if="type == 1">二维码</template>
                <template v-if="type == 2">证件照</template>
                <template v-if="type == 3">自定义图片</template>
              </span>
              <span slot="strong" slot-scope="text, record">
                <template v-if="record.type == 0">
                  <template v-if="record.strong">是</template>
                  <template v-else-if="!record.strong">否</template>
                </template>
              </span>
              <span slot="firstIndent" slot-scope="text, record">
                <template v-if="record.type == 0">
                  <template v-if="record.firstIndent">是</template>
                  <template v-else-if="!record.firstIndent">否</template>
                </template>
              </span>
              <span slot="align" slot-scope="align">
                <template v-if="align == 0">左对齐</template>
                <template v-if="align == 1">居中</template>
                <template v-if="align == 2">右对齐</template>
              </span>
              <template slot="colorRGB" slot-scope="text, record">
                <div
                  v-if="record.type == 0"
                  :style="`height: 14px; width: 26px; background: ${
                    record.colorRGB ? record.colorRGB : '#000'
                  };`"
                ></div>
              </template>
              <span slot="action" slot-scope="info">
                <pxkeji-btn
                  name="编辑"
                  :roles="[]"
                  size="small"
                  @click="showModal(info)"
                  type="link"
                />
                <pxkeji-btn
                  name="删除"
                  :roles="[]"
                  size="small"
                  @click="delInfo(info.id)"
                  type="link"
                  style="color: #ff4d4f"
                />
              </span>
            </a-table>
          </a-col>
        </a-row>
        <a-form-model-item
          :wrapper-col="{ span: 14, offset: 6 }"
          style="margin-top: 40px"
        >
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <a-modal
        title="证书内容设置"
        v-model="visible"
        :footer="false"
        destroyOnClose
        width="900px"
        :maskClosable="false"
      >
        <certificateContent :contentObj="contentObj" @callback="contentBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import certificateContent from "./certificateContent";
let _this;
export default {
  props: {
    id: String,
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      certTypeList: [],
      form: {
        status: true,
      },
      rules: {
        type: [
          { required: true, message: "请选择证书类型", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入证书名称", trigger: "blur" }],
        numPrefix: [
          { required: true, message: "请输入证书编号前缀", trigger: "blur" },
        ],
        sequenceLength: [
          {
            required: true,
            message: "请输入编号顺序号位数",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "change" },
        ],
        validity: [
          {
            required: true,
            message: "请输入证书有效期",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "change" },
        ],
      },
      fileList: [],
      previewVisible: false,
      visible: false,
      contentList: {
        columns: [
          {
            dataIndex: "type",
            align: "center",
            title: "内容类型",
            width: 88,
            scopedSlots: { customRender: "type" },
          },
          {
            dataIndex: "alias",
            align: "center",
            title: "别名",
            width: 75,
            ellipsis: true,
          },
          {
            dataIndex: "offsetX",
            align: "center",
            title: "横坐标",
            width: 60,
          },
          {
            dataIndex: "offsetY",
            align: "center",
            title: "纵坐标",
            width: 60,
          },
          {
            dataIndex: "width",
            align: "center",
            title: "图片宽",
            width: 60,
          },
          {
            dataIndex: "height",
            align: "center",
            title: "图片高",
            width: 60,
          },
          {
            dataIndex: "content",
            align: "center",
            title: "内容",
            ellipsis: true,
          },
          {
            dataIndex: "resetOffsetX",
            align: "center",
            title: "重置横坐标",
            width: 90,
          },
          {
            dataIndex: "validLength",
            align: "center",
            title: "有效长度",
            width: 75,
          },
          {
            dataIndex: "typeface",
            align: "center",
            title: "字体",
            width: 80,
            ellipsis: true,
          },
          {
            dataIndex: "colorRGB",
            align: "center",
            title: "颜色",
            width: 45,
            scopedSlots: { customRender: "colorRGB" },
          },
          {
            dataIndex: "fontSize",
            align: "center",
            title: "字号",
            width: 45,
          },
          {
            dataIndex: "strong",
            align: "center",
            title: "加粗",
            width: 45,
            scopedSlots: { customRender: "strong" },
          },
          {
            dataIndex: "align",
            align: "center",
            title: "对齐方式",
            width: 75,
            scopedSlots: { customRender: "align" },
          },
          {
            dataIndex: "firstIndent",
            align: "center",
            title: "首行缩进",
            width: 75,
            scopedSlots: { customRender: "firstIndent" },
          },
          {
            dataIndex: "lineHeight",
            align: "center",
            title: "行高",
            width: 45,
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            width: 115,
            scopedSlots: { customRender: "action" },
          },
        ],
        datas: [],
      },
      contentObj: "",
    };
  },
  mounted() {
    _this = this;
    _this.getCertTypeList();
    if (_this.id > 0) {
      _this.getInfo();
    }
  },
  components: {
    certificateContent,
  },
  methods: {
    async getCertTypeList() {
      let result = await _this.$api.dictionaryItem.getDictionaryItem(33);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.certTypeList = result.data;
      }
    },
    getInfo() {
      _this.isloading = true;
      _this.$api.certificate
        .getById(_this.id)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.form = res.data;
            _this.fileList.push({
              uid: res.data.id,
              name: "证书图片" + res.data.id + ".png",
              status: "done",
              url: res.data.imageUrl,
            });
            _this.form.status = this.$commen.changeIntBoolean(res.data.status);
            _this.form.trainGeneral = this.$commen.changeIntBoolean(
              res.data.trainGeneral
            );
            _this.contentList.datas = JSON.parse(res.data.content);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.certificateForm.validate((valid) => {
        if (valid) {
          if (_this.contentList.datas.length == 0) {
            _this.$message.error("请设置证书内容");
            return;
          }
          if (!_this.form.imageUrl) {
            _this.$message.error("请上传证书背景");
            return;
          }
          let data = { ..._this.form };
          data.status = _this.$commen.changeBooleanInt(data.status);
          data.trainGeneral = _this.$commen.changeBooleanInt(data.trainGeneral);
          data.content = JSON.stringify(_this.contentList.datas);
          _this.isloading = true;
          _this.$api.certificate
            .saveOrUpdate(data)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    showModal(info) {
      _this.contentObj = info;
      _this.visible = true;
    },
    contentBack(val) {
      if (val) {
        let obj = JSON.parse(val);
        if (obj.id) {
          for (let i = 0; i < _this.contentList.datas.length; i++) {
            if (_this.contentList.datas[i].id == obj.id) {
              _this.contentList.datas.splice(i, 1, obj);
              break;
            }
          }
        } else {
          obj.id = _this.$commen.guid();
          _this.contentList.datas.push(obj);
        }
      }
      _this.visible = false;
    },
    delInfo(id) {
      for (let i = 0; i < _this.contentList.datas.length; i++) {
        if (_this.contentList.datas[i].id == id) {
          _this.contentList.datas.splice(i, 1);
          break;
        }
      }
    },
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        _this.$message.error("文件不能超过5MB!");
      }
      return isJPGPNG && isLt5M;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.form.imageUrl = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.form.imageUrl = "";
      }
    },
  },
};
</script>
<style>
</style>